<template>
  <div>
    <b-row>
      <b-col cols="12" md="6" lg="3" v-for="(item, index) in activeListData" :key="index">
        <productCard :item="item" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <EKPagination :pageLength="8" :items="productsList" v-model="activeListData" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import EKPagination from "@Ekcore/components/EK-pagination";
import productCard from "./components/product-card.vue";
import { mapActions, mapGetters } from "vuex";
import { handleDashLangChange } from "@/libs/event-bus";
export default {
  components: {
    EKPagination,
    productCard,
  },
  data: () => ({
    activeListData: [],
  }),
  created() {
    this.fetchProducts();
    handleDashLangChange(this.fetchProducts);
  },
  computed: {
    ...mapGetters(["productsList"]),
  },
  methods: {
    ...mapActions(["fetchProducts"]),
  },
  beforeDestroy() {
    this.$store.commit("Reset_Search_Dto");
  },
};
</script>
