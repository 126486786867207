<template>
  <b-card no-body class="overflow-hidden">
    <b-card-header class="p-1">
      <div>
        <h4 class="font-large-1">
 
            {{ item.name }}
        
        </h4>
        <p v-if="item.categories[0]" style="color: darkslateblue">
             {{ item.categories[0].mainCategory }}
        </p>
        <span v-if="item.categories[0]">
             {{ item.categories[0].subCategory }}
        </span>
      </div>
    </b-card-header>
    <b-card-body class="px-1">
      <img
        style="height: 165px; object-fit: contain"
        :src="
          item.images[0]
            ? item.images[0].path
              ? item.images[0].path.replace(/^/, $store.getters['app/domainHost'] + '/')
              : 'https://picsum.photos/id/870/200/300?grayscale&blur=2'
            : 'https://picsum.photos/id/870/200/300?grayscale&blur=2'
        "
        class="rounded w-100"
      />
    </b-card-body>
    <b-card-footer class="p-0">
      <b-button
        variant="primary"
        :to="'/admin/products/' + item.id"
        block
        squared
        class="rounded-t-0"
        >عرض التفاصيل</b-button
      >
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  props: {
    item: Object,
  },
};
</script>
<style scoped>
.min-height-card {
  min-height: 20px;
}
</style>
